<template>
  <div>
    <!-- 首页轮播 -->
    <div style="position: relative;">
      <!-- <swiper :options="swiperOption" ref="mySwiper" style="width: 100%; overflow: hidden;">
        <swiper-slide class="swiper-slide" v-for="(item, index) in 2" :key="index" style="overflow: hidden; width: 100%;"> -->
          <img src="@/image/index/banner.jpg" class="index_img" />
        <!-- </swiper-slide>
      </swiper> -->
      <!-- <img src="@/image/bg_02.png" class="index_img" style="position: absolute;bottom: 0;z-index: 1;" /> -->
      <!-- 公告 -->
      <notice class="hand" style="position: absolute;bottom:20px;z-index: 2;width: 100%;"></notice>
    </div>
    <!-- 开头 -->
    <div class="title flex_center">
      <img :src="require('@/image/index/' + $t('index.img8'))" alt="">
    </div>
    <div class="part0 common_text">{{$t('index.part0.title')}}</div>
    <img src="@/image/index/34_02.png" class="index_img" alt="">
    <!-- 段落1 -->
    <div class="part1">
      <div class="title flex_center" style="width:50%;">
        <img src="@/image/index/parl_01.png" alt="">
      </div>
      <div class="part_title" style="font-size:var(--text);padding:0 10px;">{{$t('index.part1.title')}}</div>
      <img src="@/image/index/34_05.png" class="index_img" style="margin: 40px 0;" alt="">
    </div>
    <!-- 段落2 -->
    <div class="part2">
      <img src="@/image/index/34_08.png" class="part_bg2" alt="">
      <div>
        <div class="title flex_center" style="width:150px;margin-left:0;">
          <img src="@/image/index/parl_02.png" alt="">
        </div>
        <img src="@/image/index/34_11.png" style="float:right;width: 50%;margin-right:-10px;" alt="">
        <div class="part_title" style="font-size:calc(var(--text) - 2px);">{{$t('index.part2.title')}}</div>
        <div class="list_title" style="font-size:calc(var(--text) + 3px);">{{$t('index.part2.title1')}}</div>
        <div class="list_text" style="font-size:calc(var(--text) - 2px);">{{$t('index.part2.text1')}}</div>
      </div>
    </div>
    <!-- 段落3 -->
    <div class="part3 flex_lr">
      <img src="@/image/index/34_13.png" class="part_bg2" alt="">
      <img src="@/image/index/34_16.png" class="part_bg" alt="">
      <!-- <div></div> -->
      <div style="text-align: right;width: 50%;padding-right: 10px;">
        <div class="title flex_center" style="width:150px;margin-right:0;">
          <img src="@/image/index/parl_03.png" alt="">
        </div>
        <div class="list_title" style="font-size:calc(var(--text) + 2px);">{{$t('index.part3.title1')}}</div>
        <div class="list_text" style="font-size:calc(var(--text) - 2px);">{{$t('index.part3.text1')}}</div>
        <div class="list_text" style="font-size:calc(var(--text) - 2px);">{{$t('index.part3.text2')}}</div>
      </div>
    </div>
    <!-- 段落4 -->
    <div class="part4">
      <div class="title flex_center" style="width:50%;">
        <img src="@/image/index/parl_04.png" alt="">
      </div>
      <!-- <div class="line"></div> -->
      <div class="part_text" style="font-size:calc(var(--text) - 2px);">{{$t('index.part4.text')}}</div>
    </div>
    <!-- 段落5 -->
    <div class="part5 y_center">
      <div class="title flex_center" style="width:50%;">
        <img src="@/image/index/parl_05.png" alt="">
      </div>
      <div class="part_title" style="font-size:calc(var(--text) + 6px)">{{$t('index.cooperation')}}</div>
      <div class="line"></div>
      <div class="partner flex_lr">
        <div class="partner_list flex_center"><img src="@/image/index/partner_01.png" alt=""></div>
        <div class="partner_list flex_center"><img src="@/image/index/partner_02.png" alt=""></div>
        <div class="partner_list flex_center"><img src="@/image/index/partner_03.png" alt=""></div>
        <div class="partner_list flex_center"><img src="@/image/index/partner_04.png" alt=""></div>
      </div>
      <div class="part_title" style="font-size:calc(var(--text) + 6px)">{{$t('index.contact')}}</div>
      <div class="line"></div>
      <div class="contact flex_pj">
        <div class="y_center" style="width:25%;">
          <img src="@/image/index/34_20.png" @click="goUrl('https://t.me/MTDAO_CN')" alt="">
          <div>Telegram-CN</div>
        </div>
        <div class="y_center" style="width:25%;">
          <img src="@/image/index/34_20.png" @click="goUrl('https://t.me/MTDAO_Official')" alt="">
          <div>Telegram-EN</div>
        </div>
        <div class="y_center" style="width:25%;">
          <img src="@/image/index/34_22.png" @click="goUrl('https://twitter.com/mtdao777')" alt="">
          <div>Twitter</div>
        </div>
        <div class="y_center" style="width:25%;">
          <img src="@/image/index/34_25.png" @click="$copy('mtdao777@gmail.com')" alt="">
          <div>Email</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import walletHelper from "@/utils/walletHelper.js"
import notice from "@/components/Notice";
export default {
  name: "index",
  components: {
    notice,
  },
  data() {
    return {
      swiperOption: {
        loop: true, //是否循环轮播
        speed: 1000, //切换速度
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //自动轮播
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        //设置slider容器能够同时显示的slides数量
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      }
    };
  },
  mounted() {
    this.init();
  },
  computed: {
		wallet_address() {
			return this.$store.state.user.wallet_address
		}
	},
  methods: {
    init() {
      var id = this.$route.query.id
      if(id!=undefined){
        this.$store.commit('user/SET_PARENT_ADDRESS', id)
        if(this.wallet_address!='点击连接钱包'){
          walletHelper.bind(this.wallet_address,id)
        }
      }else{
        this.$store.commit('user/SET_PARENT_ADDRESS', '')
        if(this.wallet_address!='点击连接钱包'){
          walletHelper.bind(this.wallet_address,'')
        }
      }
    },
    goUrl(url){
      window.open(url)
    },
  },
};
</script>
<style lang="scss" scoped>
// @import '../css/common.scss';
.index_img {
  width: 100%;
}
.title{
  background: url('../image/index/biaoti_11.png');
  background-size: 200px 100%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding: 25px 0;
  margin: -15px auto;
  img{
    // height: 100%;
    width: 100%;
  }
}
.part0{
  font-family: PingFang SC;
  color: #FEFEFE;
  text-align: center;
  margin-top: -20px;
}
.part1{
  .part_title{
    font-family: PingFang SC;
    font-weight: bold;
    color: #FFFFFF;
    text-align: center;
  }
}
.part2{
  position: relative;
  padding: 0 10px;
  display: inline-block;
  .part_bg{
    position: absolute;
    z-index: -1;
    width: 80%;
    right: 0;
    top: 40px;
  }
  .part_bg2{
    position: absolute;
    z-index: -1;
    height: 50px;
    right: 0;
    top: 0;
  }
  .part_title{
    font-family: PingFang SC;
    margin: 5px 0;
    color: #FFFFFF;
    letter-spacing: 1px;
  }
  .list_title{
    font-family: PingFang SC;
    color: #4DD2CF;
    margin: 20px 0 10px;
  }
  .list_text{
    font-family: PingFang SC;
    color: #FFFFFF;
  }
}
.part3{
  position: relative;
  // padding: 0 10px;
  margin-top: 50px;
  .part_bg{
    // position: absolute;
    // left: 0;
    // top: 50px;
    // bottom: 0;
    // z-index: -1;
    // margin: auto;
    margin-top: 50px;
    width: 50%;
  }
  .part_bg2{
    position: absolute;
    z-index: -1;
    height: 50px;
    left: 0;
    top: 0;
  }
  .part_title{
    font-family: PingFang SC;
    margin: 5px 0;
    color: #FFFFFF;
    letter-spacing: 3px;
  }
  .list_title{
    font-family: PingFang SC;
    color: #4DD2CF;
    margin: 20px 0 10px;
  }
  .list_text{
    font-family: PingFang SC;
    color: #FFFFFF;
    margin-top: 10px;
  }
}
.part4{
  margin-top: 50px;
  .part_title{
    font-family: PingFang SC;
    font-weight: bold;
    text-align: center;
    color: #1CC6BC;
    margin: 20px 0;
  }
  .line{
    width: 60%;
    height: 1px;
    background: linear-gradient(to right,rgba(0,0,0,0),rgba(26, 167, 165, 1),rgba(0,0,0,0));
    margin: 0 auto;
  }
  .part_text{
    font-family: PingFang SC;
    color: #FFFFFF;
    padding: 20px;
  }
}
.part5{
  margin-top: 50px;
  .part_title{
    font-family: PingFang SC;
    // font-weight: bold;
    text-align: center;
    color: #ffffff;
    margin: 20px 0;
  }
  .line{
    width: 60%;
    height: 1px;
    background: linear-gradient(to right,rgba(0,0,0,0),rgba(26, 167, 165, 1),rgba(0,0,0,0));
    margin: 0 auto;
  }
  .partner{
    width: 100%;
    padding: 30px;
    flex-wrap: wrap;
    .partner_list{
      width: 48%;
      background: #0B9A98;
      margin-bottom: 10px;
      // min-height: 60px;
      border-radius: 5px;
      img{
        width: 100%;
      }
    }
  }
  .contact{
    width: 100%;
    font-size: 13px;
    font-family: PingFang SC;
    // font-weight: bold;
    color: #FFFFFF;
    padding: 50px 0 100px;
    img{
      width: 50px;
      margin-bottom: 5px;
    }
  }
}
</style>