<template>
  <div class="y_center" style="padding: 5px 0;" v-if="notice!=''">
    <div class="flex_lr index_box" @click="gonotice()">
      <i class="ifont icone-tongzhigonggao"></i>
      <div class="text">
        <transition name="slide">
          <div class="txt" :key="number">{{notice[number].title}}</div>
        </transition>
      </div>
      <!-- <div class="hand" style="color: #AE7B0B;margin-left: 10px;" @click="gonotice()">{{$t('index.more')}}</div> -->
    </div>
  </div>
</template>
 
<script>
export default {
  props:['list'],
  name: 'scroll',
  data () {
    return {
      number: 0,
      notice:[],
      last_page:0,
      current_page:0,
    }
  },
  computed: {
    
  },
  mounted () {
    this.init()
  },
  methods: {
    init() {
      this.get('/api/Community/announcement', {page:this.current_page}, 'POST' ,true).then(res => {
        this.last_page = res.list.last_page;//分页总数
        this.current_page = res.list.current_page;//当前页
        if (this.current_page==1) {
          this.notice = res.list.data
        } else {
          if(res.list.data.length>0){
            this.notice = this.notice.concat(res.list.data)
          }
        }
        console.log(this.notice)
        this.startMove()
      })
    },
    startMove () {
      setTimeout(() => {
        if (this.number >= this.notice.length-1) {
          this.number = 0;
        } else {
          this.number++;
        }
        this.startMove();
      }, 2500); 
    },
    gonotice() {
      this.$router.push('/notice')
    }
  }
}
</script>
 
<style lang="scss" scoped>
.index_box{
  position: relative;
  padding: 0 15px;
  width: 96%;
  // background-color: #E3DEB4;
  background: linear-gradient(to right,rgba(19, 50, 183, 0),rgba(19, 50, 183, 0.8) 10% 90%,rgba(19, 50, 183, 0));
  // border-radius: 10px;
  height: 40px;
  color: #67A8BE;
  i{
    color: #67A8BE;
    font-size: 25px;
    margin-right: 5px;
  }
  .text{
    position: relative;
    width: 0;
    flex: 1;
    margin: auto;
    overflow: hidden;
    .txt{
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .slide-enter-active, .slide-leave-active {
    transition: all 0.5s linear;
  }
  .slide-enter{
    transform: translateY(20px) scale(1);
    opacity: 1;
  }
  .slide-leave-to {
    transform: translateY(-20px) scale(0.8);
    opacity: 0;
  }
  .img{
    width: 40px;
    height: 40px;
  }
}
</style>